
import './App.css';
import NavBar from './components/Navbar/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './components/Banner/Banner';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import PrayerRequest from './components/PrayerRequest/PrayerRequest';
import Give from './components/Give/Give';
import Faq from './components/FAQ/Faq';
import Articles from './components/Articles/Articles';
import AddArticlePage from './components/Articles/AddArticlePage';
import Testimonies from './components/Testimonies/Testimonies';

import SignUp from './components/SignPages/SignUp';
import SignIn from './components/SignPages/SignIn';


function App() {
  return (
    <Router>
      <div>
        <NavBar/>
        <Routes>
        <Route path="/" element={<Banner />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/give" element={<Give />} />
          <Route path='/about' element={<About/>} />
          <Route path='/request' element={<PrayerRequest/>} />
          <Route path='/testimonies' element={<Testimonies/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='/articles' element={<Articles/>} />
          <Route path="/add-article" element={<AddArticlePage />} />


          <Route path="/signup" element={<SignUp/>} />
          <Route path="signin" element={<SignIn/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
