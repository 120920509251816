import React from 'react';
import Footer from '../Footer/Footer';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddArticleButton from './AddArticleButton'; // Import the new AddArticleButton component

function Articles() {
  return (
    <section id="articles" className="bg-gray-100 py-10">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-blue-900 to-blue-600 text-white text-center py-10">
        <h1 className="text-4xl lg:text-6xl font-bold mb-3">Articles of Faith</h1>
        <p className="text-xl">Inspiration and spiritual growth from Mizpah International Ministry</p>
      </div>

      {/* Introduction Section */}
      <div className="container mx-auto py-8 px-4 lg:px-20 text-center text-gray-700">
        <p className="text-lg lg:text-xl leading-relaxed">
          At Mizpah, we deeply understand the value of the Word in spiritual growth. Just as Psalm 119:105 says, 
          "Your word is a lamp to my feet and a light to my path." Our articles aim to provide that lamp, illuminating 
          your spiritual journey. Dive in and let these articles inspire, guide, and strengthen your faith.
        </p>
      </div>

      {/* Articles Section */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-8 px-4 lg:px-20">
        {/* Placeholder for each article - You can map over your articles here */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img
            src="https://via.placeholder.com/300x200"
            alt="Article"
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h3 className="text-2xl font-bold mb-2">Article Title</h3>
            <p className="text-gray-600 mb-4"></p>
            <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
              Read More
            </button>
          </div>
        </div>
        {/* Repeat the above block for each article */}
      </div>

      {/* Add Article Button */}
      <AddArticleButton />

      {/* Footer */}
      <Footer />
    </section>
  );
}

export default Articles;
