import React, { useEffect } from 'react'
import '../Testimonies/Testimonies.css'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';



const testimoniesData = [
    {
        id: '5',
        from: "St Lucia",
        message: "Since joining Mizpah, my life is transformed. My son now walks, my first child speaks. My prayer life has improved, and I've discovered my gifts. My marriage is peaceful, and I'm grateful for Pastor Kwame. I've introduced friends to Mizpah too.",
        link: "/request",
        buttontext: "Send Prayer Request"
    },
    {
        id: '4',
        from: "St Vincent",
        message: "Hallelujah! Since becoming part of Mizpah, my faith and prayer life have grown. I've received the Holy Spirit's gift and embraced humility. I pray God keeps blessing this ministry. Amen!",
        link: "/contact",
        buttontext: "Contact Us"
    },
    {
        id: '3',
        from: "Uganda",
        message: "Praise God indeed! Since joining Mizpah, I've experienced His faithfulness. He healed my persistent headache through prayer. I got a job, it doesn't pay much, but I'm grateful. My prayer life has improved tremendously. All glory to God!",
        link: "/request",
        buttontext: "Send Prayer Request"
    },
    {
        id: '0',
        from: "Kenya",
        message: "I'm here to share my blessings since joining Mizpah. Your prayers brought me healing and helped my baby niece recover. Your guidance on keeping a note has brought me financial luck. I'm grateful for your encouragement to trust in God, and I'm hopeful for my daughter's job, my future spouse, and an international job for me. All glory to God for His answers are here and Amen.",
        link: "/contact",
        buttontext: "Send Prayer Request"
    },
    {
        id: '1',
        from: "Ghana",
        message: "I'm deeply grateful for the Mizpah family and Pastor Kyei's life-changing ministry. Special thanks to Cecilia Esi for guiding me to Mizpah. Since joining in 2021, my spiritual journey has been enriched, prayer life deepened, and relationship with God strengthened. Amen.",
        link: "/contact",
        buttontext: "Contact Us"
    },
    {
        id: '2',
        from: "Kenya",
        message: "Hallelujah! Since joining the Mizpah family, I've been delivered many times. Pastor Kingsley's guidance through my challenges has transformed my family's life and mine. My prayer life has enhanced, my divine gift has flourished, and my dreams are clearer. I'm truly grateful for Mizpah's impact.",
        link: "/request",
        buttontext: "Send Prayer Request"
    },

]
function Testimonies() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div id='testimonies'>
            <div id='testimonies-header' className='testimonies-banner font-pFonts text-white text-center'>
                <h1 className='pt-[150px] font-h2Fonts text-6xl  pb-3'>Testimonies</h1>
                <h3 className='pb-[150px]'>Transformative Journeys: Stories of Faith and Growth at Mizpah International</h3>
            </div>
            <p className='p-3 text-justify font-pFonts'> As stated in Romans 10:17, "Faith comes from hearing the message," and we believe in sharing those messages. In 2 Corinthians 1:3-4, we're reminded that God comforts us in all our troubles so we can comfort others. These testimonies do just that. They comfort, inspire, and strengthen our faith. Let's rejoice in our shared stories of faith, just as Psalms 66:16 urges us, "Come and hear, all you who fear God; let me tell you what he has done for me." Welcome to our community of shared stories testimonies.</p>

            <div className="d-flex flex-wrap">
                {
                    testimoniesData.map(({ from, message, id, link, buttontext }) => {
                        return (
                            <div className='col-md-4 col-12' key={id}>
                                <Card style={{ width: '95%' }} className='m-3 main-card'>
                                    <Card.Body>
                                        <Card.Title className='font-titleFonts text-center text-6xl'>TESTIMONY</Card.Title>
                                        <Card.Subtitle className="mb-2 font-pFonts text-uppercase pt-3">From: {from}</Card.Subtitle>
                                        <Card.Text className="message-text text-justify">
                                            {message}
                                        </Card.Text>
                                        <Link to={link} >
                                            <button className='bg-primary text-white p-3 rounded-lg'>
                                                {buttontext}</button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    }
                    )
                }
            </div>
            <Footer />
        </div>
    )
}

export default Testimonies
