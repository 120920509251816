import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Avatar } from '@mui/material';

function AddArticlePage() {
    const [upload,setUpload] = useState(true)
  const [article, setArticle] = useState({
    title: '',
    body: '',
    bibleVerse: '',
    summary: '',
    prayer: '',
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setUpload(false)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to submit the article and image to the backend or Firebase
    console.log('Article Published:', article);
    if (image) {
      console.log('Image:', image);
    }
    // Clear form and image after submission
    setArticle({
      title: '',
      body: '',
      bibleVerse: '',
      summary: '',
      prayer: '',
    });
    setImage(null);
    setImagePreview('');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography
          variant="h4"
          component="h1"
          textAlign="center"
          color="primary"
          gutterBottom
        >
          Publish Your Article
        </Typography>

        <form onSubmit={handleSubmit}>
          {/* Article Title */}
          <TextField
            label="Article Title"
            name="title"
            value={article.title}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          {/* Article Body */}
          <TextField
            label="Article Body"
            name="body"
            value={article.body}
            onChange={handleChange}
            multiline
            rows={6}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          {/* Bible Verse */}
          <TextField
            label="Bible Verse Reading"
            name="bibleVerse"
            value={article.bibleVerse}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            placeholder="e.g., John 3:16"
          />

          {/* Summary */}
          <TextField
            label="Summary"
            name="summary"
            value={article.summary}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          {/* Prayer */}
          <TextField
            label="Prayer"
            name="prayer"
            value={article.prayer}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          {/* Image Upload */}
          <Box mt={2}>
            <input
              accept="image/*"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="image-upload"
              required
            />
            <label htmlFor="image-upload">
              <Button variant="contained" color="secondary" component="span">
              {upload ? "Pick an Image" :"Change Image"}
              </Button>
            </label>
          </Box>

          {/* Display Selected Image */}
          {imagePreview && (
            <Box mt={2} textAlign="center">
              <Avatar
                src={imagePreview}
                alt="Selected Image"
                sx={{
                  width: 200,
                  height: 200,
                  borderRadius: 2,
                  boxShadow: 3,
                  objectFit: 'cover',
                }}
              />
            </Box>
          )}

          {/* Publish Button */}
          <Box textAlign="center" mt={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Publish Article
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default AddArticlePage;
