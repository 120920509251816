import React from 'react';
import { Button } from '@mui/material'; // Importing Material UI Button

const MeetingSchedule = () => {
  const scheduleData = [
    {
      day: 'Sunday',
      times: [
        { country: 'USA', time: '5:00 - 6:00 PM' },
        { country: 'Ghana', time: '9:00 - 10:00 PM' },
        { country: 'Jamaica', time: '4:00 - 5:00 PM' },
        { country: 'Kenya', time: '12:00 - 1:00 AM' },
        { country: 'UK', time: '10:00 - 11:00 PM' },
      ],
    },
    {
      day: 'Mon - Fri (Morning)',
      times: [
        { country: 'USA', time: '5:15 - 6:00 AM' },
        { country: 'Ghana', time: '9:15 - 10:00 AM' },
        { country: 'Jamaica', time: '4:15 - 5:00 AM' },
        { country: 'Kenya', time: '12:15 - 1:00 PM' },
        { country: 'UK', time: '10:15 - 11:00 AM' },
      ],
    },
    {
      day: 'Mon - Thurs (Evening)',
      times: [
        { country: 'USA', time: '7:00 - 8:00 PM' },
        { country: 'Ghana', time: '11:00 - 12:00 Midnight' },
        { country: 'Jamaica', time: '6:00 - 7:00 PM' },
        { country: 'Kenya', time: '2:00 - 3:00 AM' },
        { country: 'UK', time: '12:00 - 1:00 AM' },
      ],
    },
  ];

  return (
    <div className="bg-white px-3">
      <h2 className="text-dark-600 text-3xl font-bold mb-4 text-center">Meeting Schedule</h2>

      {scheduleData.map((schedule, index) => (
        <div key={index} className="mb-6">
          <h3 className="text-black text-xl font-bold mb-2">{schedule.day}</h3>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 gap-4">
          {schedule.times.map((timeInfo, idx) => (
            <div
              key={idx}
              className="bg-purple-100 rounded-lg border border-purple-400 h-20 flex flex-col justify-center px-2"
            >
              <p className="text-black font-medium m-0">{timeInfo.country}</p>
              <p className="text-purple-600 font-bold m-0">{timeInfo.time}</p>
            </div>
          ))}
          
          </div>
        </div>
      ))}

      <div className="text-center mb-2">
        <h4 className="text-black text-lg font-semibold mb-2">Join Zoom Meeting</h4>
        <p className="text-purple-600 font-semibold mb-2">
          <a
            href="https://us02web.zoom.us/j/3022979645?pwd=UFIvYTloM09uZ1IyeFZ5TG9RVng0UT09"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to Join
          </a>
        </p>
        <p className="text-black">
          <span className="font-semibold">Meeting ID:</span> 302 297 9645
        </p>
        <p className="text-black">
          <span className="font-semibold">Passcode:</span> pray
        </p>

        <Button
          variant="contained"
          color="primary"
          href="https://us02web.zoom.us/j/3022979645?pwd=UFIvYTloM09uZ1IyeFZ5TG9RVng0UT09"
          className="mt-4 pb-2"
          style={{
            backgroundColor: 'purple',
            color: 'white',
            
          }}
        >
          Join Zoom Meeting
        </Button>
      </div>
    </div>
  );
};

export default MeetingSchedule;
