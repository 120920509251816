import React from 'react';
import PastorImage from '../Images/pastor.png'; // Replace with the correct path to your image

const PastorComponent = () => {
  return (
    <section className="bg-gray-50 py-16 px-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between space-y-10 md:space-y-0 md:space-x-10">
        
        {/* Left Section - Pastor's Image */}
        <div className="md:w-1/2 flex justify-center">
          <div className="relative overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out">
            <img
              src={PastorImage}
              alt="Pastor Kwame K Kingsley"
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        </div>

        {/* Right Section - Pastor's Bio */}
        <div className="md:w-1/2 text-gray-800">
          <h1 className="text-5xl font-extrabold text-purple-600 mb-6 text-center md:text-left">
            Meet Our Pastor
          </h1>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <blockquote className="text-justify text-lg leading-relaxed text-gray-700 relative">
              <span className="absolute top-0 left-0 transform -translate-x-6 -translate-y-6 text-purple-600 text-6xl font-bold">“</span>
              Pastor Kwame K Kingsley, a devoted servant of God, focuses on prayer, healing, and deliverance. Born in Ghana's Ashanti region, his commitment to the church began early. Pastor Kwame's leadership journey started at True Disciples Pentecost Church, where he led the youth ministry.
              <br /><br />
              In Italy, he contributed to the Holy Spirit Assemblies of God church, notably as a bass guitarist. After 7 years, he initiated a prayer meeting in his home, which quickly grew into a significant gathering in Modena, Italy.
              <br /><br />
              In 2013, Pastor Kwame established Mizpah International Ministry, with its roots in the USA, focusing on prayer, deliverance, and spiritual growth.
              <span className="absolute bottom-0 right-0 transform translate-x-6 translate-y-6 text-purple-600 text-6xl font-bold">”</span>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PastorComponent;
